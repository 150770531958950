import { axiosClient } from "./axios-client";
import { clearTempLoginData, getToken, setTempLoginData } from "./helper";

export const loginUser = async (userId, password) => {
  setTempLoginData({ userid: userId, password });
  const response = await axiosClient.get("product-owners/" + userId + "/contracts/states/open?page=1&pageSize=1");
  clearTempLoginData();
  console.log('LoginUser response', response);
  return response.data;
}

export const authenticateUser = async (username, password) => {
  const response = await axiosClient.post("auth/token", { username, password });
  console.log('AuthenticateUser response', response);
  return response.data;
}

export const refreshAccessToken = async () => {
  const response = await axiosClient.post("/auth/refresh", {
    refreshToken: getToken()?.refreshToken,
  });
  console.log('refreshAccessToken', response);
  return response.data;
}

/*export const getReportData = async (reportType, fetchProps) => {
  const userData = getToken();
  const reportTypeName = reportType === 0 ? 'open' : reportType === 1 ? 'approved' : 'declined';
  const response = await axiosClient
      .get(`product-owners/${userData.userid}/contracts/states/${reportTypeName}`, { params: fetchProps });
  console.log('getReportData response', response);
  return response.data;
}

export const updateContractStatus = async (approved, declined) => {
  const userData = getToken();
  const response = await axiosClient
      .post(`product-owners/${userData.userid}/contracts/states`, { success: approved, decline: declined });
  console.log('updateContractStatus response', response);
  return response;
}

export const getAdminPlatforms = async () => {
  const response = await axiosClient.get("admin/platforms");
  console.log('GetAdminPlatforms response', response);
  return response.data;
}

export const getPlatformProducts = async (platform, startDate, endDate, fetchProps) => {
  const response = await axiosClient
      .get(`admin/platforms/${platform}/products`, { params: { startDate, endDate, ...fetchProps } });
  console.log('GetPlatformProducts response', response);
  return response.data;
}

export const getContractsOfProduct = async (platform, product, type, status, startDate, endDate, fetchProps) => {
  const response = await axiosClient
      .get(`admin/platforms/${platform}/products/${product}/${type}/${status}`, {
        params: {
          startDate,
          endDate, ...fetchProps
        }
      });
  console.log('GetContractsOfProduct response', response);
  return response.data;
}

export const getPlatformContracts = async (reportType, fetchProps) => {
  const reportTypeName = reportType === 0 ? 'open' : reportType === 1 ? 'approved' : 'declined';
  const response = await axiosClient
      .get(`compliance/contracts/states/${reportTypeName}`, { params: fetchProps });
  console.log('getPlatformContracts response', response);
  return response.data;
}

export const updatePlatformContractStatus = async (approved, declined) => {
  const response = await axiosClient
      .post(`compliance/contracts/states`, { approved, declined });
  console.log('updatePlatformContractStatus response', response);
  return response;
}*/
