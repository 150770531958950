import React from "react";
import LoginPage from "./containers/login";

import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import {
  ADMIN_USER_ROLE,
  AdminPrivateRoute,
  PrivateRoute,
  VGV_USER_ROLE
} from "./library/helper";

import ToastManager from "./components/toast";

// pages
import DashboardPage from "./containers/dashboard";
import NotfoundPage from "./containers/notfound";
import HelpPage from "./containers/help";
import AdminDashboard from "./containers/admin-dashboard";
import { useSelector } from "react-redux";

const LoginPageRedirect = () => {
  const userRole = useSelector(state => state.authentication.userRole);

  switch (userRole) {
    case VGV_USER_ROLE:
      return <Redirect to={'/vgv-login'} />
    case ADMIN_USER_ROLE:
      return <Redirect to={'/admin-login'} />
    default:
      return <Redirect to={'/login'} />
  }
}

function App() {
  return (
      <>
        <Router>
          <Switch>
            <Route exact path="/" component={LoginPageRedirect} />
            {/*<Route exact path="/RegisterPage" component={RegisterPage} />*/}
            {/*<Route
                exact
                path="/ChangepasswordPage"
                component={ChangepasswordPage}
            />*/}
            {/*<Route
                exact
                path="/ForgotpasswordPage"
                component={ForgotpasswordPage}
            />*/}
            <Route exact path="/login" component={LoginPage} />
            <Route exact path="/admin-login" component={LoginPage} />
            <Route exact path="/vgv-login" component={LoginPage} />
            <PrivateRoute exact path="/dashboard" component={DashboardPage} />
            <AdminPrivateRoute exact path="/admin-dashboard" component={AdminDashboard} />
            <AdminPrivateRoute exact path="/vgv-dashboard" component={DashboardPage} />
            <Route exact path='/help' component={HelpPage} />
            <Route path="*" component={NotfoundPage} />
          </Switch>
        </Router>

        <ToastManager />
      </>
  );
}

export default App;
