import React from 'react';
import { axiosClient, createAxiosResponseInterceptor } from "./axios-client";
import { clearTempLoginData, getToken, setTempLoginData } from "./helper";
import { useHistory } from "react-router-dom";

const useApi = () => {
  const history = useHistory();
  createAxiosResponseInterceptor(history);

  const loginUser = async (userId, password) => {
    setTempLoginData({ userid: userId, password });
    const response = await axiosClient.get("product-owners/" + userId + "/contracts/states/open?page=1&pageSize=1");
    clearTempLoginData();
    console.log('LoginUser response', response);
    return response.data;
  }

  const authenticateUser = async (username, password) => {
    const response = await axiosClient.post("auth/token", { username, password });
    console.log('AuthenticateUser response', response);
    return response.data;
  }

  const getReportData = async (reportType, fetchProps) => {
    const userData = getToken();
    const reportTypeName = reportType === 0 ? 'open' : reportType === 1 ? 'approved' : 'declined';
    const response = await axiosClient
        .get(`product-owners/${userData.userid}/contracts/states/${reportTypeName}`, { params: fetchProps });
    console.log('getReportData response', response);
    return response.data;
  }

  const updateContractStatus = async (approved, declined) => {
    const userData = getToken();
    const response = await axiosClient
        .post(`product-owners/${userData.userid}/contracts/states`, { success: approved, decline: declined });
    console.log('updateContractStatus response', response);
    return response;
  }

  const getAdminPlatforms = async () => {
    const response = await axiosClient.get("admin/platforms");
    console.log('GetAdminPlatforms response', response);
    return response.data;
  }

  const getAdminPlatformProducts = async (platform, fetchProps) => {
    const response = await axiosClient
        .get(`admin/platforms/${platform}/products`, { params: fetchProps  });
    console.log('getAdminPlatformProducts response', response);
    return response.data;
  }

  const getContractsOfProduct = async (platform, product, type, status, fetchProps) => {
    const response = await axiosClient
        .get(`admin/platforms/${platform}/products/${product}/${type}/${status}`, {
          params: fetchProps
        });
    console.log('GetContractsOfProduct response', response);
    return response.data;
  }

  const getPlatformContracts = async (reportType, fetchProps) => {
    const reportTypeName = reportType === 0 ? 'open' : reportType === 1 ? 'approved' : 'declined';
    const response = await axiosClient
        .get(`compliance/contracts/states/${reportTypeName}`, { params: fetchProps });
    console.log('getPlatformContracts response', response);
    return response.data;
  }

  const updatePlatformContractStatus = async (approved, declined) => {
    const response = await axiosClient
        .post(`compliance/contracts/states`, { approved, declined });
    console.log('updatePlatformContractStatus response', response);
    return response;
  }

  const getPlatformProducts = async (fetchProps = {}) => {
    const response = await axiosClient
        .get(`products`, { params: fetchProps });
    console.log('getPlatformProducts response', response);
    return response.data;
  }

  return {
    loginUser,
    authenticateUser,
    getReportData,
    updateContractStatus,
    getAdminPlatforms,
    getAdminPlatformProducts,
    getContractsOfProduct,
    getPlatformContracts,
    updatePlatformContractStatus,
    getPlatformProducts
  };
};

export default useApi;
