import React, { useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import "../assets/css/login.css";

import * as Yup from "yup";
import { Form, FormikProvider, useFormik } from "formik";
import { useHistory, useLocation } from "react-router-dom";

import {
  ADMIN_USER_ROLE,
  CheckBearerToken,
  CheckToken,
  getUserRoleFromToken,
  PRODUCT_OWNER_USER_ROLE,
  VGV_USER_ROLE
} from "../library/helper";
import { authenticateUser, authenticateUserByBearer, setUserRole } from "../library/store/authentication";
import { useDispatch, useSelector } from "react-redux";
import { toastMessage } from "../library/store/toast";
import { Helmet } from "react-helmet";

export default function LoginPage() {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const loginError = useSelector((state) => state.authentication.error);
  const loginInfo = useSelector((state) => state.authentication.loginData);
  const userRoleByRouteName = location.pathname === '/admin-login' ? ADMIN_USER_ROLE :
      location.pathname === '/vgv-login' ? VGV_USER_ROLE : PRODUCT_OWNER_USER_ROLE;

  const LoginSchema = Yup.object().shape({
    userid: Yup.string().required("name is required"),
    password: Yup.string().required("password is required"),
  });

  useEffect(() => {
    if (userRoleByRouteName !== PRODUCT_OWNER_USER_ROLE ? CheckBearerToken() : CheckToken()) {
      const userRoleFromToken = getUserRoleFromToken();
      dispatch(setUserRole(userRoleFromToken));
      history.push(userRoleFromToken === ADMIN_USER_ROLE ? '/admin-dashboard' :
          userRoleFromToken === VGV_USER_ROLE ? '/vgv-dashboard' : "/dashboard");
    }
  }, [loginInfo]);

  useEffect(() => {
    if (loginError) {
      dispatch(toastMessage({ severity: 'error', summary: 'login error', detail: loginError }))
    }
  }, [loginError]);


  const formik = useFormik({
    initialValues: {
      userid: "",
      password: "",
    },
    validationSchema: LoginSchema,
    onSubmit: (data) => {
      console.log('login data', data);
      dispatch(userRoleByRouteName !== PRODUCT_OWNER_USER_ROLE ? authenticateUserByBearer(data) : authenticateUser(data))
          .finally(() => formik.setSubmitting(false))
    },
  });

  const { errors, touched, isSubmitting, handleSubmit } = formik;

  return (
      <>
        <Helmet>{process.env.REACT_APP_APP_TITLE} | Login</Helmet>
        <div className="form-box"
             style={{ backgroundImage: `url(${process.env.PUBLIC_URL + '/bg.jpg'})`, backgroundSize: 'cover' }}>
          <div className="fullHeight p-ai-center p-d-flex p-jc-center">
            <div className="shadow card m-3 px-3 py-4 px-sm-4 py-sm-5">
              <h4 className="text-center">CONCEDUS Contracts Board</h4>
              {userRoleByRouteName === ADMIN_USER_ROLE && <h4 className="text-center text-danger">Administrator</h4>}
              {userRoleByRouteName === VGV_USER_ROLE && <h4 className="text-center text-danger">VGV</h4>}
              <p className="text-center mb-3">Geschützter Bereich</p>
              <FormikProvider value={formik}>
                <Form onSubmit={handleSubmit} className="p-fluid">
                  <div className="p-field">
                <span className="p-float-label">
                  <InputText
                      id="userid"
                      name="userid"
                      value={formik.values.userid}
                      onChange={formik.handleChange}
                      className={classNames({
                        "p-invalid": Boolean(touched.userid && errors.userid),
                      })}
                  />
                  <label
                      htmlFor="name"
                      className={classNames({
                        "p-error": Boolean(touched.userid && errors.userid),
                      })}
                  >
                    Username
                  </label>
                </span>
                    {Boolean(touched.userid && errors.userid) && (
                        <small className="p-error">{formik.errors["userid"]}</small>
                    )}
                  </div>

                  <div className="p-field">
                <span className="p-float-label">
                  <Password
                      id="password"
                      name="password"
                      toggleMask
                      feedback={false}
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      className={classNames({
                        "p-invalid": Boolean(touched.password && errors.password),
                      })}
                  />
                  <label
                      htmlFor="password"
                      className={classNames({
                        "p-error": Boolean(touched.password && errors.password),
                      })}
                  >
                    Password
                  </label>
                </span>
                    {Boolean(touched.password && errors.password) && (
                        <small className="p-error">{formik.errors["password"]}</small>
                    )}
                    {/*<div className="fpassword" onClick={onForgotPassword}>
                    Forgot password?
                  </div>*/}
                  </div>

                  <div className="submitBtnBox">
                    <Button
                        type="submit"
                        label="Login"
                        iconPos="right"
                        loading={isSubmitting}
                        className="mt-4 submitBtn"
                        disabled={isSubmitting}
                    />
                  </div>
                </Form>
              </FormikProvider>

              {/*<div className="signupBox mt-3 text-center">
              Don't have account? <Link to="/RegisterPage">Register Now</Link>
            </div>*/}
            </div>
          </div>
        </div>
      </>
  );
}
