import React from "react";
import { ThemeProvider } from "@mui/material";
import { Container, Grid } from "@material-ui/core";
import { useDispatch, useSelector, batch } from "react-redux";
import {
  defaultMaterialTheme,
  VGV_PAGE_DOCUMENTATION,
  VGV_PAGE_FORMULA,
  VGV_PAGE_INFORMATION, VGV_PAGE_INVOICE
} from "../library/constant";
import { formatCurrency } from "../library/currency-formatter";
import { Helmet } from "react-helmet";
import { PRODUCT_OWNER_USER_ROLE, VGV_USER_ROLE } from "../library/helper";
import { TabPanel, TabView } from "primereact/tabview";
import UserDataTable from "./UserDataTable";
import { setVGVActivePage, setVGVSelectedTab } from "../library/store/dashboard";
import VGVDocsViewer from "./VGVDocsViewer";
import VGVUpdatesNewsViewer from "./VGVUpdatesNewsViewer";

const SummarySection = () => {
  const reportSummary = useSelector(state => state.dashboard.value.summary);
  return (
      <div className='w-100 mt-2 p-2 d-flex justify-content-end' style={{ gap: '.333rem' }}>
        <span className='mr-5 font-weight-bold'>Total</span>
        <span className='font-weight-bold'>Share:</span>
        <span className='mr-5'>{formatCurrency(reportSummary.sumShare)}</span>
        <span className='font-weight-bold'>Agio:</span>
        <span>{formatCurrency(reportSummary.sumAgio)}</span>
      </div>
  )
}

export default function DashboardPage() {
  const dispatch = useDispatch();
  const userRole = useSelector(state => state.authentication.userRole);
  const vgvSelectedTab = useSelector(state => state.dashboard.value.vgvSelectedTab);

  return (
      <>
        <Helmet>
          {(userRole === PRODUCT_OWNER_USER_ROLE) && <title>{process.env.REACT_APP_APP_TITLE} | Dashboard</title>}
          {(userRole === VGV_USER_ROLE) && <title>{process.env.REACT_APP_APP_TITLE} | VGV Dashboard</title>}
        </Helmet>
        <Grid>
          <Container maxWidth='xl'>
            <ThemeProvider theme={defaultMaterialTheme}>
              {
                userRole === VGV_USER_ROLE ?
                    <TabView activeIndex={vgvSelectedTab}
                             onTabChange={(e) => batch(() => {
                               dispatch(setVGVSelectedTab(e.index));
                               dispatch(setVGVActivePage(null))
                             })}>
                      <TabPanel header={VGV_PAGE_INVOICE}>
                        <UserDataTable />
                        <SummarySection />
                      </TabPanel>
                      <TabPanel header={VGV_PAGE_FORMULA}>
                        <VGVDocsViewer title={VGV_PAGE_FORMULA} />
                      </TabPanel>
                      <TabPanel header={VGV_PAGE_DOCUMENTATION}>
                        <VGVDocsViewer title={VGV_PAGE_DOCUMENTATION} />
                      </TabPanel>
                      <TabPanel header={VGV_PAGE_INFORMATION}>
                        <VGVDocsViewer title={VGV_PAGE_INFORMATION} />
                      </TabPanel>
                    </TabView>
                    :
                    <>
                      <UserDataTable />
                      <SummarySection />
                    </>
              }
            </ThemeProvider>
          </Container>
        </Grid>
      </>
  );
}
