import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { loginUser, authenticateUser as authenticateUserBearer } from "../auth-api";
import { getUserRoleFromToken, setToken } from "../helper";

const initialState = {
  error: null,
  isLogged: false,
  loginData: null,
  userRole: getUserRoleFromToken()
};

export const authenticateUser = createAsyncThunk(
    "authentication/user",
    async (data, thunkAPI) => {
      try {
        const response = await loginUser(data.userid, data.password);
        // sessionStorage.setItem("loginData", JSON.stringify(data));
        setToken(data);
        return response;
      } catch (error) {
        console.error('authenticateUserThunk error', error);
        const msg = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(msg);
      }
    }
);

export const authenticateUserByBearer = createAsyncThunk(
    "authentication/user-bearer",
    async (data, thunkAPI) => {
      try {
        const response = await authenticateUserBearer(data.userid, data.password);
        // sessionStorage.setItem('admin-token', JSON.stringify(response));
        setToken(response)
        return response;
      } catch (error) {
        console.error('authenticateUserByBearer error', error);
        const msg = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(msg);
      }
    }
);

export const authenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    setUserRole: (state, action) => {
      state.userRole = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
        .addCase(authenticateUser.fulfilled, (state, action) => {
          state.isLogged = true;
          state.loginData = action.payload;
          state.error = null;
        })
        .addCase(authenticateUser.rejected, (state, action) => {
          state.isLogged = false;
          state.loginData = null;
          state.error = action.payload;
        })
        .addCase(authenticateUser.pending, (state, action) => {
          state.isLogged = false;
          state.loginData = null;
          state.error = null;
        })
        .addCase(authenticateUserByBearer.fulfilled, (state, action) => {
          state.isLogged = true;
          state.loginData = action.payload;
          state.error = null;
        })
        .addCase(authenticateUserByBearer.rejected, (state, action) => {
          state.isLogged = false;
          state.loginData = null;
          state.error = action.payload;
        })
        .addCase(authenticateUserByBearer.pending, (state, action) => {
          state.isLogged = false;
          state.loginData = null;
          state.error = null;
        })
  },
});

export const { setUserRole } = authenticationSlice.actions;

export default authenticationSlice.reducer;
