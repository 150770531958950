import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: {
    selectedDataType: 0,
    contracts: [],
    platforms: [],
    activePlatform: '',
    adminContractsModalVisible: false,
    adminContractsModalActiveType: '',
    adminContractsModalActiveStatus: '',
    adminContractsModalActiveProduct: '',
    summary: { sumShare: 0, sumAgio: 0 },
    vgvSelectedTab: 0,
    vgvActivePage: '',
    tablePageSize: localStorage.getItem('bapp_ps') ?? 10
  }
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setDataType: (state, action) => {
      state.value.selectedDataType = action.payload;
    },
    setContracts: (state, action) => {
      state.value.contracts = action.payload;
    },
    setPlatforms: (state, action) => {
      state.value.platforms = action.payload;
    },
    setActivePlatform: (state, action) => {
      state.value.activePlatform = action.payload;
    },
    setAdminContractsModalVisible: (state, action) => {
      state.value.adminContractsModalVisible = action.payload;
    },
    setActiveTypeInAdminContractsModal: (state, action) => {
      state.value.adminContractsModalActiveType = action.payload;
    },
    setActiveStatusInAdminContractsModal: (state, action) => {
      state.value.adminContractsModalActiveStatus = action.payload;
    },
    setActiveProductInAdminContractsModal: (state, action) => {
      state.value.adminContractsModalActiveProduct = action.payload;
    },
    setDashboardSummary: (state, action) => {
      state.value.summary = action.payload;
    },
    setVGVSelectedTab: (state, action) => {
      state.value.vgvSelectedTab = action.payload;
    },
    setVGVActivePage: (state, action) => {
      state.value.vgvActivePage = action.payload;
    },
    setTablePageSize: (state, action) => {
      state.value.tablePageSize = action.payload;
      localStorage.setItem('bapp_ps', action.payload);
    }
  },
});

export const {
  setDataType,
  setContracts,
  setPlatforms,
  setActivePlatform,
  setAdminContractsModalVisible,
  setActiveTypeInAdminContractsModal,
  setActiveStatusInAdminContractsModal,
  setActiveProductInAdminContractsModal,
  setDashboardSummary,
  setVGVSelectedTab,
  setVGVActivePage,
  setTablePageSize
} = dashboardSlice.actions;

export default dashboardSlice.reducer;