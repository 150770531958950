import React, { useEffect, useState } from "react";

import { NavLink } from "react-router-dom";
import { Sidebar } from "primereact/sidebar";
import { useDispatch, useSelector } from "react-redux";
import { closeSideBar } from "../library/store/sidebar";

import "../assets/css/menu.css";
import { setActivePlatform, setDataType, setVGVActivePage } from "../library/store/dashboard";
import useApi from "../library/useApi";
import { ADMIN_USER_ROLE, VGV_USER_ROLE } from "../library/helper";
import { Button } from "primereact/button";
import { getPageSubMenus } from "../library/store/pocket-base-service";
import { VGV_PAGE_TABS } from "../library/constant";
// import { getAdminPlatforms } from "../library/api";

const contractTypesMenu = [
  {
    name: "Offene Verträge",
    route: "/dashboard",
    iconType: "component",
  },
  {
    name: "Angenommene Verträge",
    route: "/dashboard",
    iconType: "component",
  },
  {
    name: "Abgelehnte Verträge",
    route: "/dashboard",
    iconType: "component",
  },
];

export default function SideBar() {
  const [drawerVisible, setDrawerVisible] = useState();
  const [menus, setMenus] = useState([]);
  const drawerState = useSelector((state) => state.sidebar.value);
  const currentDataType = useSelector((state) => state.dashboard.value.selectedDataType);
  const currentActivePlatform = useSelector((state) => state.dashboard.value.activePlatform);
  const vgvSelectedTab = useSelector((state) => state.dashboard.value.vgvSelectedTab);
  const vgvActivePage = useSelector((state) => state.dashboard.value.vgvActivePage);
  const userRole = useSelector(state => state.authentication.userRole);
  const dispatch = useDispatch();
  const isAdmin = userRole === ADMIN_USER_ROLE;
  const isVgv = userRole === VGV_USER_ROLE;
  const { getAdminPlatforms } = useApi();

  useEffect(() => {
    if (isAdmin) {
      getAdminPlatforms()
          .then((data) => setMenus(data.platforms.map(p => ({
            name: p,
            route: '/admin-dashboard',
            iconType: "component"
          }))))
          .catch((err) => console.error('fetch platforms failed', err))
    } else {
      if (isVgv) {
        switch (vgvSelectedTab) {
          case 0:
            setMenus(contractTypesMenu);
            break;
          case 1:
          case 2:
          case 3:
            getPageSubMenus(VGV_PAGE_TABS[vgvSelectedTab])
                .then((menus) => setMenus(menus.map(m => ({
                  id: m.id,
                  name: m.name,
                  iconType: 'component',
                  route: '/vgv-dashboard'
                }))));
            break;
        }
      } else {
        setMenus(contractTypesMenu);
      }
    }
  }, [vgvSelectedTab])

  useEffect(() => {
    setDrawerVisible(drawerState);
  }, [drawerState]);

  const isLinkActive = (item, index) => {
    if (isAdmin) {
      return currentActivePlatform === item.name;
    } else if (isVgv) {
      // Static pages
      if ('id' in item) {
        if (vgvActivePage) {
          return item.id === vgvActivePage;
        } else {
          // When tab changes, active page is null and so the selection should be on the first sub-menu item
          return index === 0;
        }
      } else {
        // Contracts page
        return currentDataType === index;
      }
    } else {
      return currentDataType === index;
    }
  };
  const onLinkClick = (item, index) => {
    if (isAdmin) {
      dispatch(setActivePlatform(item.name));
    } else if (isVgv) {
      // Static pages
      if ('id' in item) {
        dispatch(setVGVActivePage(item.id));
      } else {
        // Contracts page
        dispatch(setDataType(index));
      }
    } else {
      dispatch(setDataType(index));
    }
  }

  return (
      <div
          className="p-col-fixed p-d-none p-d-lg-block h-100 sidebarWrapper"
          style={{ width: "16%" }}
      >
        {/* side drawer for mobile */}
        <Sidebar
            visible={drawerVisible}
            onHide={() => {
              dispatch(closeSideBar());
            }}
        >
          {/* {menuContent} */}
          <div className="menus">
            {menus.map((item, index) => (
                <div className="focusonSelect" key={index}>
                  <NavLink
                      to={item.route}
                      className="p-d-flex p-ai-center p-text-secondary"
                      activeStyle={{
                        fontWeight: "bold",
                        color: `var(--primary-color)`,
                      }}
                      isActive={() => isLinkActive(item, index)}
                      onClick={() => onLinkClick(item, index)}
                  >
                    {item.iconType === "component" && item.icon}
                    <span className="ml-2 menuText">{item.name}</span>
                  </NavLink>
                </div>
            ))}
          </div>
        </Sidebar>

        {/* normal sidebar */}
        <div className="menuSidebar p-d-none p-d-lg-flex h-100">
          {/* {menuContent} */}
          <div className="menus">
            {isVgv && <Button style={{ visibility: 'hidden' }}>Test</Button>}
            {menus.map((item, index) => (
                <NavLink
                    key={index}
                    to={item.route}
                    className="p-d-flex p-ai-center p-text-secondary"
                    activeStyle={{
                      fontWeight: "bold",
                      color: `var(--primary-color)`,
                    }}
                    isActive={() => isLinkActive(item, index)}
                    onClick={() => onLinkClick(item, index)}
                >
                  {item.iconType === "component" && item.icon}
                  <span className="ml-2 menuText">{item.name}</span>
                </NavLink>
            ))}
          </div>
        </div>
      </div>
  );
}
