import React, { useEffect, useMemo, useState } from 'react';
import { Button } from "primereact/button";
import { getPageFirstSubMenuItem, getPageSubMenuItem } from "../library/store/pocket-base-service";
import { saveAs } from 'file-saver';
import { useDispatch, useSelector } from "react-redux";
import { toastMessage } from "../library/store/toast";

const VGVDocsViewer = ({ title }) => {
  const dispatch = useDispatch();
  const activePage = useSelector(state => state.dashboard.value.vgvActivePage);
  const [pageContent, setPageContent] = useState(null);

  useEffect(() => {
    (activePage ? getPageSubMenuItem(activePage) : getPageFirstSubMenuItem(title))
        .then(res => {
          console.log(res);
          setPageContent(res);
        });
  }, [activePage]);

  const header = useMemo(() => pageContent?.header, [pageContent]);
  const secHeader = useMemo(() => pageContent?.secondary_header, [pageContent]);
  const body = useMemo(() => pageContent?.body, [pageContent]);
  const downloads = useMemo(() => Array.isArray(pageContent?.downloads) ? pageContent?.downloads : [], [pageContent]);
  const showDownloadColumn = useMemo(() => downloads?.some(d => !!d.downloadURL), [pageContent]);
  const showRedirectColumn = useMemo(() => downloads?.some(d => !!d.redirectURL), [pageContent]);
  const downloadButtonClick = (url) => {
    try {
      saveAs(url);
    } catch (e) {
      dispatch(toastMessage({ severity: 'error', summary: 'Download Error', detail: e.message || JSON.stringify(e) }));
      console.error('download file failed', e);
    }
  }

  return (
      <div>
        <h4>{header || title}</h4>
        <p>{body}</p>
        {secHeader && <h5 className='mt-4'>{secHeader}</h5>}
        {downloads?.length ?
            <table style={{ width: '100%', marginTop: '2rem' }}>
              <thead>
              <tr>
                <th className='border-bottom py-2'>Bezeichnung</th>
                {showDownloadColumn && <th className='border-bottom py-2'>Download</th>}
                {showRedirectColumn && <th className='border-bottom py-2'>Online ausfüllen</th>}
              </tr>
              </thead>
              <tbody>
              {
                pageContent?.downloads.map((r, i) => (
                    <tr key={i}>
                      <td className='pr-3 py-2'>{r.name}</td>
                      {showDownloadColumn && <td className='pr-3 py-2'>
                        <Button style={{ visibility: !r.downloadURL ? 'hidden' : 'visible' }} className="p-button-help"
                                onClick={() => downloadButtonClick(r.downloadURL)}>
                          Download
                        </Button>
                      </td>}
                      {showRedirectColumn && <td className='pr-3 py-2'>
                        <Button style={{ visibility: !r.redirectURL ? 'hidden' : 'visible' }}
                                className="p-button-help" onClick={() => downloadButtonClick(r.redirectURL)}>
                          Jetzt ausfüllen
                        </Button>
                      </td>}
                    </tr>
                ))
              }
              </tbody>
            </table>
            :
            null
        }
      </div>
  );
};

export default VGVDocsViewer;
