import React, { useCallback, useEffect, useRef, useState } from 'react';
import { tableIcons } from "../library/constant";
import { PRODUCT_OWNER_USER_ROLE, VGV_USER_ROLE } from "../library/helper";
import MaterialTable, { MTableToolbar } from "material-table";
import { MultiSelect } from "primereact/multiselect";
import {setDashboardSummary, setTablePageSize} from "../library/store/dashboard";
import { useDispatch, useSelector } from "react-redux";
import ApproveIcon from "@material-ui/icons/Done";
import DeclineIcon from "@material-ui/icons/Cancel";
import useApi from "../library/useApi";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { toastMessage } from "../library/store/toast";
import { Button } from "primereact/button";

const ConfirmDialogFooter = ({ proceedClickHandler, cancelClickHandler }) => {
  return (
      <div>
        <Button label="Proceed" icon="pi pi-check"
                onClick={proceedClickHandler} />
        <Button label="Cancel" icon="pi pi-times" className="p-button-warning"
                onClick={cancelClickHandler} />
      </div>
  )
}
const parseContracts = (data, userRole) => {
  if (userRole === PRODUCT_OWNER_USER_ROLE) {
    return data.map((c, i) => ({
      id: i,
      investmentDate: c.signed,
      investmenttype: c.type === "RV3" ? "Retail" : "Professionell",
      contractKey: c.key,
      buyer: c.investorName || c.companyName,
      product: c.product,
      amount: c.share,
      agio: c.agio,
      total: (c.agio || 0) + (c.share || 0)
    }));
  } else if (userRole === VGV_USER_ROLE) {
    return data.map((c, i) => ({
      id: i,
      investmentDate: c.signed,
      investmenttype: c.type === "RV2" ? "Retail" : "Professionell",
      contractKey: c.key,
      updated: c.updatedDate,
      product: c.product,
      amount: c.share,
      agio: c.agio,
      total: (c.agio || 0) + (c.share || 0)
    }));
  }
}
const UserDataTable = () => {
  const dispatch = useDispatch();
  const userRole = useSelector(state => state.authentication.userRole);
  const dataType = useSelector(state => state.dashboard.value.selectedDataType);
  const tbPageSize = useSelector(state => state.dashboard.value.tablePageSize);
  const [selectTypeName, setSelectTypeName] = useState("");
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [confirmDlgOpened, setConfirmDlgOpened] = useState(false);
  const [userAction, setUserAction] = useState('approve'); // approve | decline
  const [declineReason, setDeclineReason] = useState('');
  const tableRef = useRef();
  const {
    getReportData,
    updateContractStatus,
    getPlatformContracts,
    updatePlatformContractStatus,
    getPlatformProducts
  } = useApi();
  const queryFunction = useCallback((query) => {
    let filter = {};
    if (selectedProducts.length) {
      filter['product'] = selectedProducts;
    }

    const reqParams = {
      page: query.page + 1,
      pageSize: query.pageSize,
      search: query.search,
      ...filter
    };

    return (userRole === PRODUCT_OWNER_USER_ROLE ?
        getReportData(dataType, reqParams) :
        getPlatformContracts(dataType, reqParams))
        .then((data) => {
          dispatch(setDashboardSummary(data.summary));
          return {
            data: parseContracts(data.contracts, userRole),
            page: data.summary.pageNo - 1,
            totalCount: data.summary.itemsCount
          };
        });
  }, [dataType, JSON.stringify(selectedProducts)]);

  useEffect(() => {
    if (dataType === 0) {
      setSelectTypeName("Offene Verträge");
    } else if (dataType === 1) {
      setSelectTypeName("Angenommene Verträge");
    } else if (dataType === 2) {
      setSelectTypeName("Abgelehnte Verträge");
    } else {
      setSelectTypeName("Offene Verträge");
    }
    // Force re-fresh data on contract-type change, and go to first page
    tableRef.current.onQueryChange({ page: 0, pageSize: tbPageSize });
  }, [dataType]);
  useEffect(() => {
    if (userRole === VGV_USER_ROLE) {
      getPlatformProducts()
          .then((response) => setProducts(response));
    }
  }, []);

  const prodOwnerColumns = [
    { title: "ID", field: "id" },
    { title: "Type", field: "investmenttype" },
    { title: "Vertrags-Key", field: "contractKey" },
    { title: "Investmentdatum", field: "investmentDate" },
    { title: "Käufer", field: "buyer" },
    { title: "Produkt", field: "product" },
    {
      title: "Anteilspreis",
      field: "amount",
      type: "currency",
      currencySetting: {
        locale: "de",
        currencyCode: "eur",
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      },
    },
    {
      title: "AGIO",
      field: "agio",
      type: "currency",
      currencySetting: {
        locale: "de",
        currencyCode: "eur",
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      },
    },
    {
      title: "Gesamtinvestitionsbetrag",
      field: "total",
      type: "currency",
      currencySetting: {
        locale: "de",
        currencyCode: "eur",
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      },
    },
  ];
  const platformColumns = [
    { title: "ID", field: "id", filtering: false },
    { title: "Type", field: "investmenttype", filtering: false },
    { title: "Vertrags-Key", field: "contractKey", filtering: false },
    { title: "Investmentdatum", field: "investmentDate", filtering: false },
    {
      title: "Produkt",
      field: "product"
    },
    {
      title: "Anteilspreis",
      field: "amount",
      type: "currency",
      currencySetting: {
        locale: "de",
        currencyCode: "eur",
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      },
    },
    {
      title: "AGIO",
      field: "agio",
      type: "currency",
      currencySetting: {
        locale: "de",
        currencyCode: "eur",
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      },
    },
    {
      title: "Gesamtinvestitionsbetrag",
      field: "total",
      type: "currency",
      currencySetting: {
        locale: "de",
        currencyCode: "eur",
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      },
    }
  ];
  let tableOptions = {
    selection: dataType === 0,
    search: true,
    actionsColumnIndex: -1,
    grouping: false,
    rowStyle: (rowData) => {
      return {
        backgroundColor: "transparent",
      };
    },
    pageSizeOptions: [5, 10, 25, 50, 100, 250, 300, 400],
    pageSize: tbPageSize
  };
  const tableActions = dataType === 0 ?
      [
        {
          icon: () => <span style={{ fontSize: '1rem' }}><ApproveIcon color='primary' /> Annehmen</span>,
          tooltip: "Annehmen",
          name: "Annehmen",
          onClick: (event, rowData) => {
            setUserAction('approve')
            setConfirmDlgOpened(true);
          },
        },
        {
          icon: () => <span style={{ fontSize: '1rem' }}><DeclineIcon color='error' /> Ablehnen</span>,
          tooltip: "Ablehnen",
          name: "Ablehnen",
          onClick: (event, rowData) => {
            setUserAction('decline')
            setConfirmDlgOpened(true);
          },
        },
      ] : [];
  const onRowClick = (event, rowData) => {
    // Apply only for Open contracts table
    /*if (dataType === 0) {
      const newData = contracts.map(row => {
        // Find the clicked row
        if (row.tableData?.id === rowData.tableData?.id) {
          return {
            ...row,
            tableData: {
              // Toggle the check state
              checked: !rowData.tableData?.checked
            }
          }
        }
        return row;
      });
      // Set the new data containing the checked row
      setContracts(newData);
    }*/
  }
  const onSelectedProductsChange = (e) => {
    setSelectedProducts(e.value);
    // Force re-fresh data on contract-type change, and go to first page
    tableRef.current.onQueryChange({ page: 0, pageSize: tbPageSize });
  }
  const onConfirmDlgProceedClicked = () => {
    const approved = userAction === 'approve' ? selectedRows.map(x => ({
      key: x.contractKey,
      reason: ''
    })) : [];
    const declined = userAction === 'decline' ? selectedRows.map(x => ({
      key: x.contractKey,
      reason: declineReason
    })) : [];

    (userRole === PRODUCT_OWNER_USER_ROLE ?
        updateContractStatus(approved, declined) :
        updatePlatformContractStatus(approved, declined))
        .then((res) => {
          console.log('Update success', res);
          // Show info message
          dispatch(toastMessage({
            severity: 'success',
            summary: userAction === 'approve' ? 'Approved' : 'Declined',
            detail: userAction === 'approve' ?
                `${approved.length} Contract(s) approved successfully` :
                `${declined.length} Contract(s) declined successfully`
          }));
          // Force re-fresh data
          tableRef.current.onQueryChange({ page: 0, pageSize: tbPageSize });
        })
        .catch((err) => {
          console.error('Update error', err);
          // Show error message
          dispatch(toastMessage({
            severity: 'error',
            summary: userAction === 'approve' ? 'Approve Failed' : 'Decline Failed',
            detail: userAction === 'approve' ?
                `Failed to approve ${approved.length} Contract(s)` :
                `Failed to decline ${declined.length} Contract(s)`
          }))
        });

    setConfirmDlgOpened(false);
  }

  const onConfirmDlgCancelClicked = () => {
    setConfirmDlgOpened(false);
  }

  return (
      <>
        <MaterialTable
            fluid
            style={{ margin: '0 -1.25rem' }}
            icons={tableIcons}
            tableRef={tableRef}
            columns={userRole === PRODUCT_OWNER_USER_ROLE ? prodOwnerColumns : platformColumns}
            data={queryFunction}
            title={selectTypeName}
            localization={{
              pagination: {
                labelDisplayedRows:
                    "{from}-{to} von {count} Verträgen angezeigt",
                labelRowsSelect: "Verträge",
                labelRowsPerPage: "Verträge je Seite",
              },
              toolbar: {
                nRowsSelected: "{0} Verträge ausgewählt",
              },
              header: {
                actions: "Actions",
              },
              body: {
                emptyDataSourceMessage: "Keine Verträge zur Freigabe",
                filterRow: {
                  filterTooltip: "Filter",
                },
              },
            }}
            options={tableOptions}
            actions={tableActions}
            onSelectionChange={(rows) => setSelectedRows(rows)}
            onRowClick={onRowClick}
            onChangeRowsPerPage={(pageSize) => dispatch(setTablePageSize(pageSize))}
            components={{
              Toolbar: props => (
                  <div>
                    <MTableToolbar {...props} />
                    {userRole === VGV_USER_ROLE && <div style={{ display: 'flex' }} className='px-4 py-2'>
                      <MultiSelect value={selectedProducts} options={products}
                                   onChange={onSelectedProductsChange}
                                   placeholder="Filter by Product" maxSelectedLabels={3} />
                    </div>}
                  </div>
              )
            }}
        />
        <Dialog header="Summary"
                footer={
                  <ConfirmDialogFooter proceedClickHandler={onConfirmDlgProceedClicked}
                                       cancelClickHandler={onConfirmDlgCancelClicked} />
                }
                visible={confirmDlgOpened} style={{ width: '50vw', maxHeight: '80vh' }}
                onHide={() => setConfirmDlgOpened(false)}>
          <p>You selected {selectedRows.length} contract{selectedRows.length > 1 ? 's' : ''} to {userAction}:</p>
          <ul>
            {selectedRows.map((row, index) => (
                <li key={index}>{row.contractKey}</li>
            ))}
          </ul>
          {
            userAction === 'approve' ? null :
                <InputText id='reason' placeholder='Reason' className='mt-2 w-100'
                           onChange={(e) => setDeclineReason(e.target.value)}
                />
          }
        </Dialog>
      </>
  );
};

export default UserDataTable;
