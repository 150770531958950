import PocketBase from 'pocketbase';
import { pocketBaseApiUrl } from "../constant";

const pb = new PocketBase(pocketBaseApiUrl);

export const getVGVPage = async (name) => await pb.collection('pages')
    .getFirstListItem(`name="${name}"`);

export const getPageSubMenus = async (name) => await pb.collection('sub_menus')
    .getFullList(100, { filter: `page.name="${name}"`, expand: 'page' });

export const getPageFirstSubMenuItem = async (name) => await pb.collection('sub_menus')
    .getFirstListItem(`page.name="${name}"`, { expand: 'page' });

export const getPageSubMenuItem = async (menuId) => await pb.collection('sub_menus')
    .getOne(menuId);