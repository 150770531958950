import React, { useEffect, useState } from 'react';
import packageInfo from '../../package.json'
import { Typography } from "@mui/material";
import { axiosClient } from "../library/axios-client";

const About = () => {
  const [apiVersion, setApiVersion] = useState(null);
  useEffect(() => {
    axiosClient.get('/version')
        .then(({ data }) => setApiVersion(data));
  }, []);

  return (
      <div>
        <Typography variant='h6' gutterBottom>App name: {packageInfo.name}</Typography>
        <Typography variant='h6' gutterBottom>App version: {packageInfo.version}</Typography>
        <Typography variant='h6' gutterBottom>API version: {apiVersion?.version || 'N/A'}</Typography>
      </div>
  );
};

export default About;
