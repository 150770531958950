import React, { useRef, useState } from "react";
import "../assets/css/header.css";
import Logo from "../assets/images/logo.png";

import { useDispatch } from "react-redux";
import { openSideBar } from "../library/store/sidebar";
import { Menu } from "primereact/menu";
import { Link, useHistory } from "react-router-dom";
import HelpIcon from "@material-ui/icons/Help";
import UserIcon from "@material-ui/icons/AccountCircleRounded";
import packageInfo from '../../package.json'
import { Dialog } from "primereact/dialog";
import About from "../containers/About";
import { removeLoginData } from "../library/helper";

export default function Header() {
  const [showAbout, setShowAbout] = useState(false);
  const history = useHistory();
  const userMenuRef = useRef(null);
  // const notificationRef = useRef(null);

  const dispatch = useDispatch();

  const userMenu = [
    {
      label: "Angemeldet",
      items: [
        {
          label: "Logout",
          icon: "pi pi-power-off",
          command: () => {
            removeLoginData();
            history.push("/");
          },
        },
      ],
    },
    {
      separator: true
    }, {
      label: `Version ${packageInfo.version.split('+')[0]}`,
      command: () => setShowAbout(true)
    }
  ];

  const togglePanel = (e, ref) => {
    e.preventDefault();
    e.stopPropagation();

    document.querySelector(".emptyBoxForMenuClick").click();

    ref.current.toggle(e);
  };

  return (
      <>
        <div className="header-box d-flex p-ai-center">
          <div>
            <button
                className="p-d-inline-block p-d-lg-none btn btn-link p-0 mr-3 text-white"
                aria-label="open sidebar"
                onClick={() => {
                  dispatch(openSideBar());
                }}
            >
              <i className="pi pi-bars"></i>
            </button>
            <img src={Logo} alt="Logo" className="img img-fluid logo" />
          </div>
          <div className="ml-auto menu-items mr-0">
            <ul className="nav-list d-flex p-ai-center flex-row-reverse justify-content-center">
              <li>
                <div onClick={(e) => togglePanel(e, userMenuRef)} style={{ cursor: 'pointer' }}>
                  <UserIcon fontSize='large' style={{ color: 'white' }} color='inherit' />
                </div>
              </li>
              <li>
                <Link to='/help'>
                  <HelpIcon fontSize='large' style={{ color: 'white' }} color='inherit' />
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <Menu model={userMenu} popup ref={userMenuRef} id="user_pop_menu" />

        <div className="emptyBoxForMenuClick"></div>
        <Dialog visible={showAbout} onHide={() => setShowAbout(false)} header={'About'} style={{ width: '40vw' }}>
          <About />
        </Dialog>
      </>
  );
}
