import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link, ThemeProvider, Typography } from "@mui/material";
import MaterialTable, { MTableToolbar } from "material-table";
import { Container, Grid } from "@material-ui/core";
import { defaultMaterialTheme, tableIcons } from "../library/constant";
import { useDispatch, useSelector } from "react-redux";
// import { getPlatformProducts } from "../library/api";
import { formatCurrency } from "../library/currency-formatter";
import { Calendar } from "primereact/calendar";
import ViewIcon from '@material-ui/icons/Visibility';
import ContractsModal from "./contracts-modal";
import { Tooltip } from "primereact/tooltip";
import {
  setActiveProductInAdminContractsModal,
  setActiveStatusInAdminContractsModal,
  setActiveTypeInAdminContractsModal,
  setAdminContractsModalVisible,
  setTablePageSize
} from "../library/store/dashboard";
import { Helmet } from "react-helmet";
import useApi from "../library/useApi";

const DATE_FORMATTER = new Intl.DateTimeFormat(undefined);

const ContractTypeCell = ({ data, contractType }) => {
  const dispatch = useDispatch();

  const linkClickHandler = (status) => {
    dispatch(setAdminContractsModalVisible(true));
    dispatch(setActiveTypeInAdminContractsModal(contractType));
    dispatch(setActiveStatusInAdminContractsModal(status));
    dispatch(setActiveProductInAdminContractsModal(data.name));
  }

  return (
      <>
        <Link className='approved-link' underline='hover' color='inherit' style={{ cursor: 'pointer' }}
              onClick={() => linkClickHandler('Approved')}>
          {`${formatCurrency(data[contractType].approved.sum)} (${data[contractType].approved.count})`}
        </Link>
        <Tooltip target=".approved-link" content='Approved' position='bottom' />
        |
        <Link className='declined-link' underline='hover' color='inherit' style={{ cursor: 'pointer' }}
              onClick={() => linkClickHandler('Declined')}>
          {`${formatCurrency(data[contractType].declined.sum)} (${data[contractType].declined.count})`}
        </Link>
        <Tooltip target=".declined-link" content='Declined' position='bottom' />
      </>
  )
}

const AdminDashboard = () => {
  const [selectedMonth, setSelectedMonth] = useState(() => {
    const date = new Date();
    // Get the first of the current month
    return new Date(date.getFullYear(), date.getMonth(), 1);
  });
  const lastDayInSelectedMonth = useMemo(() =>
          new Date(selectedMonth.getFullYear(), selectedMonth.getMonth() + 1, 0, 23, 59, 59)
      , [selectedMonth]);

  const tableRef = useRef();
  const dispatch = useDispatch();
  const showContractsModal = useSelector(state => state.dashboard.value.adminContractsModalVisible);
  const activePlatform = useSelector(state => state.dashboard.value.activePlatform);
  const tbPageSize = useSelector(state => state.dashboard.value.tablePageSize);
  const { getAdminPlatformProducts } = useApi();

  let tableOptions = {
    selection: false,
    search: true,
    actionsColumnIndex: -1,
    grouping: false,
    rowStyle: (rowData) => {
      return {
        backgroundColor: "transparent",
      };
    },
    pageSizeOptions: [5, 10, 25, 100, 250],
    pageSize: tbPageSize
  };

  useEffect(() => {
    if (!activePlatform) {
      return;
    }
    // Force re-fresh data on contract-type change, and go to first page
    tableRef.current.onQueryChange({ page: 0, pageSize: tbPageSize });
  }, [activePlatform, selectedMonth]);

  const showContractsModalHandler = (event, data) => {
    dispatch(setActiveProductInAdminContractsModal(data.name));
    dispatch(setAdminContractsModalVisible(true));
  }

  const queryFunction = useCallback((query) => {
    return getAdminPlatformProducts(activePlatform, {
      startDate: selectedMonth.toISOString(),
      endDate: lastDayInSelectedMonth.toISOString(),
      page: query.page + 1,
      pageSize: query.pageSize,
      search: query.search
    }).then((data) => {
      return {
        data: data.products,
        page: data.summary.pageNo - 1,
        totalCount: data.summary.itemsCount
      };
    });
  }, [activePlatform, selectedMonth, lastDayInSelectedMonth]);


  return (
      <>
        <Helmet>
          <title>{process.env.REACT_APP_APP_TITLE} | Admin Dashboard</title>
        </Helmet>
        <Grid>
          <Container maxWidth='xl'>
            <ThemeProvider theme={defaultMaterialTheme}>
              {
                !activePlatform ?
                    <Typography variant='h5' className='mt-4 ml-4'>Please select a Platform.</Typography> :
                    <MaterialTable
                        fluid
                        icons={tableIcons}
                        tableRef={tableRef}
                        columns={[
                          // { title: "ID", field: "id" },
                          { title: "Product Name", field: "name" },
                          {
                            title: "RV4",
                            field: "RV4",
                            render: data => <ContractTypeCell data={data} contractType='RV4' />
                          },
                          {
                            title: "PV4",
                            field: "PV4",
                            render: data => <ContractTypeCell data={data} contractType='PV4' />
                          },
                          {
                            title: "RV3",
                            field: "RV3",
                            render: data => <ContractTypeCell data={data} contractType='RV3' />
                          },
                          {
                            title: "PV3",
                            field: "PV3",
                            render: data => <ContractTypeCell data={data} contractType='PV3' />
                          }, {
                            title: "RV2",
                            field: "RV2",
                            render: data => <ContractTypeCell data={data} contractType='RV2' />
                          },
                          {
                            title: "PV2",
                            field: "PV2",
                            render: data => <ContractTypeCell data={data} contractType='PV2' />
                          },
                        ]}
                        // data={products}
                        data={queryFunction}
                        title={`Platform: ${activePlatform}`}
                        localization={{
                          pagination: {
                            labelDisplayedRows:
                                "{from}-{to} von {count} Verträgen angezeigt",
                            labelRowsSelect: "Verträge",
                            labelRowsPerPage: "Verträge je Seite",
                            rowsPerPageOptions: [5, 10, 25, 100],
                          },
                          toolbar: {
                            nRowsSelected: "{0} Verträge ausgewählt",
                          },
                          header: {
                            actions: "Actions",
                          },
                          body: {
                            emptyDataSourceMessage: "Keine Verträge zur Freigabe",
                            filterRow: {
                              filterTooltip: "Filter",
                            },
                          },
                        }}
                        options={tableOptions}
                        onChangeRowsPerPage={(pageSize) => dispatch(setTablePageSize(pageSize))}
                        actions={[
                          {
                            icon: () => <ViewIcon color='primary' />,
                            tooltip: 'Show contracts',
                            onClick: showContractsModalHandler
                          }
                        ]}
                        components={{
                          Toolbar: props => (
                              <div>
                                <MTableToolbar {...props}></MTableToolbar>
                                <div className='px-4'>
                                  <Calendar value={selectedMonth}
                                            view="month" dateFormat="mm/yy"
                                            onChange={(e) => setSelectedMonth(e.value)}></Calendar>
                                  <span className='ml-3'>
                                    {DATE_FORMATTER.formatRange(selectedMonth, lastDayInSelectedMonth)}
                                  </span>
                                </div>
                              </div>
                          )
                        }}
                    />
              }
            </ThemeProvider>
          </Container>
        </Grid>
        {showContractsModal &&
            <ContractsModal platform={activePlatform} startDate={selectedMonth} endDate={lastDayInSelectedMonth} />}
      </>
  );
};

export default AdminDashboard;
