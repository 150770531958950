import axios from 'axios';
import { baseUrl } from "./constant";
import { getTempLoginData, getToken, removeLoginData, setToken } from "./helper";

// const loginData = getToken();
export const axiosClient = axios.create({
  baseURL: baseUrl,
  paramsSerializer(params) {
    const searchParams = new URLSearchParams();
    for (const key of Object.keys(params)) {
      const param = params[key];
      if (Array.isArray(param)) {
        for (const p of param) {
          searchParams.append(key, p);
        }
      } else {
        searchParams.append(key, param);
      }
    }
    return searchParams.toString();
  }
  // headers: { 'Authorization': authKey || loginData?.password }
});

axiosClient.interceptors.request.use(
    (config) => {
      const token = getToken() || getTempLoginData();
      if (token) {
        if (token.accessToken) {
          config.headers["Authorization"] = 'Bearer ' + token.accessToken;  // for Admin access
        } else if (token.password) {
          config.headers["Authorization"] = token.password; // for Client access
        }
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
);

/*axiosClient.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;
      // axiosClient.interceptors.response.eject();
      if (originalConfig.url !== "auth/token" && err.response) {
        // Access Token was expired
        if (err.response.status === 401) {
          originalConfig._retry = false;
          try {
            const rs = await axiosClient.post("/auth/refresh", {
              refreshToken: getToken()?.refreshToken,
            });
            setToken(rs.data);
            axiosClient.defaults.headers.common["Authorization"] = 'Bearer ' + rs.data.accessToken;
            return axiosClient(originalConfig);
          } catch (_error) {
            window.history.push('/login');
            return Promise.reject(_error);
          }
        }
      }

      return Promise.reject(err);
    }
);*/

/**
 * Wrap the interceptor in a function, so that I can be re-instantiated
 */
export function createAxiosResponseInterceptor(router) {
  const interceptor = axiosClient.interceptors.response.use(
      (response) => response,
      (error) => {
        // Reject promise if usual error
        if (error?.response?.status !== 401) {
          return Promise.reject(error);
        }

        const originalConfig = error.config;
        if (originalConfig.url !== "auth/token" && error.response) {
          /*
         * When response code is 401, try to refresh the token.
         * Eject the interceptor so it doesn't loop in case
         * token refresh causes the 401 response.
         *
         * Must be re-attached later on or the token refresh will only happen once
         */
          axiosClient.interceptors.response.eject(interceptor);

          return axiosClient
              .post("/auth/refresh", {
                refreshToken: getToken()?.refreshToken,
              })
              .then((response) => {

                setToken(response.data);
                error.response.config.headers["Authorization"] =
                    "Bearer " + response.data.accessToken;
                // Retry the initial call, but with the updated token in the headers.
                // Resolves the promise if successful
                return axiosClient(error.response.config);
              })
              .catch((error2) => {
                removeLoginData();
                router.push("/");
                return Promise.reject('');
              })
              .finally(() => createAxiosResponseInterceptor(router)); // Re-attach the interceptor by running the method
        }

        return Promise.reject(error);
      }
  );
}

// createAxiosResponseInterceptor(); // Execute the method once during start

// TODO: https://www.bezkoder.com/react-refresh-token/