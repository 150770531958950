import { Redirect, Route } from "react-router-dom";
import LayoutPage from "../components/layout";
import React, { useEffect, useState } from "react";
import { refreshAccessToken } from "./auth-api";
import jwtDecode from "jwt-decode";

export const PRODUCT_OWNER_USER_ROLE = 'PROD-OWNER';
export const ADMIN_USER_ROLE = 'ADMIN';
export const VGV_USER_ROLE = 'VGV';

export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={(props) =>
            CheckToken() ? (
                <LayoutPage>
                  <Component {...props} />
                </LayoutPage>
            ) : (
                <Redirect to="/" />
            )
        }
    />
);

export const CheckToken = () => {
  const token = getToken();
  return !!token;
};

export const getToken = () => {
  return JSON.parse(sessionStorage.getItem("token") || null);
};

export const setToken = (token) => {
  sessionStorage.setItem('token', JSON.stringify(token));
}

export const removeLoginData = () => {
  sessionStorage.removeItem('token');
  // sessionStorage.removeItem('admin-token');
}

// Calculate the interval that 1min before the expiration time
const calcRefreshTokenTimer = (exp) => {
  if (!exp) {
    return exp;
  }
  const expirationTimeout = new Date(exp * 1000) - new Date();
  return Math.max(expirationTimeout - 1000, 1000); // set refresh time 1 min before expiration or 1 min at least
}

export const AdminPrivateRoute = ({ component: Component, ...rest }) => {
  const [tokenExpiration, setTokenExpiration] = useState(() => {
    const token = getToken();
    return calcRefreshTokenTimer(token?.exp);
  });

  useEffect(() => {
    console.log('Refresh the token at', (new Date(new Date().getTime() + tokenExpiration)).toLocaleString());
    const interval = setTimeout(() => {
      // Refresh access token
      refreshAccessToken()
          .then((response) => {
            console.log('Token refreshed', response);
            setToken(response);
            setTokenExpiration(calcRefreshTokenTimer(response.exp));
          })
          .catch(err => console.error('Refresh token failed', err));
    }, tokenExpiration);

    return () => {
      clearTimeout(interval);
    };
  }, [tokenExpiration]);

  return (
      <Route
          {...rest}
          render={(props) =>
              CheckBearerToken() ? (
                  <LayoutPage>
                    <Component {...props} />
                  </LayoutPage>
              ) : (
                  <Redirect to="/" />
              )
          }
      />)
};

export const CheckBearerToken = () => {
  const token = getToken();
  if (isTokenExpired(parseJwt(token))) {
    return false;
  }
  return !!token;
};

/*export const getAdminToken = () => {
  return JSON.parse(sessionStorage.getItem("admin-token") || null);
};*/

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

const isTokenExpired = (token) => {
  return token && token.exp * 1000 < Date.now();
}
/* Temp Helper Functions Since Clients Login Don't have Token */
// TODO: Remove once clients login get Token

export const setTempLoginData = (data) => {
  sessionStorage.setItem('tmpLoginData', JSON.stringify(data));
}

export const getTempLoginData = () => {
  return JSON.parse(sessionStorage.getItem('tmpLoginData') || null);
}

export const clearTempLoginData = () => {
  return sessionStorage.removeItem('tmpLoginData');
}

export const getUserRoleFromToken = () => {
  const token = getToken();
  const adminRole = 'BillingAdmin';
  const vgvRole = 'VGV';
  try {
    if (token?.accessToken) {
      const decodedToken = jwtDecode(token?.accessToken);
      const { roles } = decodedToken;
      if (Array.isArray(roles)) {
        if (roles.includes(adminRole)) {
          return ADMIN_USER_ROLE;
        } else {
          return VGV_USER_ROLE;
        }
      } else {
        if (roles === adminRole) {
          return ADMIN_USER_ROLE;
        } else  {
          return VGV_USER_ROLE;
        }
      }
    } else {
      return PRODUCT_OWNER_USER_ROLE;
    }
  } catch (err) {
    console.error('Error getting user role', err);
  }
  return null;
}