import React from 'react';
import { Link } from "react-router-dom";
import Header from "../components/header";
import { Container, Typography } from "@mui/material";
import { Helmet } from "react-helmet";

const HelpPage = () => {
  return (
      <>
        <Helmet>
          <title>{process.env.REACT_APP_APP_TITLE} | Help</title>
        </Helmet>
        <Header />
        <Container className='text-center mt-4'>
          <Typography variant='h3' gutterBottom>HelpPage</Typography>
          <br />
          <Link to="/">
            <i className="pi pi-home mr-1" /> Home
          </Link>
        </Container>
      </>
  );
};

export default HelpPage;
