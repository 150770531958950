import React, { useEffect, useRef, useState } from 'react';
// import { getContractsOfProduct } from "../library/api";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Divider } from "primereact/divider";
import { formatCurrency } from "../library/currency-formatter";
import { useDispatch, useSelector } from "react-redux";
import { setAdminContractsModalVisible } from "../library/store/dashboard";
import { Paginator } from "primereact/paginator";
import useApi from "../library/useApi";

const contractTypes = [
  { label: 'PV2', value: 'PV2' },
  { label: 'PV3', value: 'PV3' },
  { label: 'PV4', value: 'PV4' },
  { label: 'RV2', value: 'RV2' },
  { label: 'RV3', value: 'RV3' },
  { label: 'RV4', value: 'RV4' },
];

const contractStatusOptions = ['Approved', 'Declined'];

const ContractsModal = ({ platform, startDate, endDate }) => {
  const dispatch = useDispatch();

  const defType = useSelector(state => state.dashboard.value.adminContractsModalActiveType);
  const defStatus = useSelector(state => state.dashboard.value.adminContractsModalActiveStatus);
  const product = useSelector(state => state.dashboard.value.adminContractsModalActiveProduct);

  const [contracts, setContracts] = useState([]);
  const [contractType, setContractType] = useState(defType ? defType : 'PV2');
  const [contractStatus, setContractStatus] = useState(defStatus ? defStatus : contractStatusOptions[0]);
  const [paginatorFirst, setPaginatorFirst] = useState(0);
  const [paginatorPageIndex, setPaginatorPageIndex] = useState(0);
  const [paginatorRows, setPaginatorRows] = useState(5);
  const [paginatorTotalRows, setPaginatorTotalRows] = useState(0);
  const paginatorRef = useRef();
  const { getContractsOfProduct } = useApi();

  useEffect(() => {
    getContractsOfProduct(platform, product, contractType, contractStatus.toLowerCase(), {
      startDate: startDate.toUTCString(),
      endDate: endDate.toUTCString(),
      page: paginatorPageIndex,
      pageSize: paginatorRows,
    })
        .then((data) => {
          setPaginatorTotalRows(data.summary.itemsCount);
          setContracts(data.contracts);
        })
        .catch((err) => console.error('fetch contracts error', err));
  }, [contractType, contractStatus, paginatorPageIndex, paginatorRows]);

  const onPaginatorChange = (event) => {
    setPaginatorFirst(event.first);
    setPaginatorRows(event.rows);
    setPaginatorPageIndex(event.page + 1);
  }

  const onContractTypeChange = (e) => {
    setContractType(e.value);
    if (paginatorRef.current) {
      // Why use `e.originalEvent`?
      // https://github.com/primefaces/primereact/blob/486c53fb30bb7fb4b0c8d6deb787e143db53bfb5/components/lib/paginator/Paginator.js#L70
      paginatorRef.current.changePageToFirst(e.originalEvent);
    }
  }

  const onContractStatusChange = (e) => {
    setContractStatus(e.value);
    if (paginatorRef.current) {
      paginatorRef.current.changePageToFirst(e.originalEvent);
    }
  }

  return (
      <Dialog header={`Contracts: ${product}`} visible
              style={{ maxWidth: '90vw', minWidth: '50vw', maxHeight: '80vh', minHeight: '300px' }}
              onHide={() => dispatch(setAdminContractsModalVisible(false))}>
        <div style={{ display: 'flex', gap: '.5rem', alignItems: 'baseline' }}>
          <label htmlFor='c-type'>Contract Type:</label>
          <Dropdown id='c-type' value={contractType} options={contractTypes}
                    onChange={onContractTypeChange} />
          <label htmlFor='c-status'>Contract Status:</label>
          <Dropdown id='c-status' value={contractStatus} options={contractStatusOptions}
                    onChange={onContractStatusChange} />
        </div>
        <Divider />
        {
          contracts.length ?
              <div className='contracts-table-container'>
                <table className='contracts-table'>
                  <thead>
                  <tr>
                    <th className='border-bottom py-2'>Key</th>
                    <th className='border-bottom py-2'>Investor</th>
                    <th className='border-bottom py-2'>Share</th>
                    <th className='border-bottom py-2'>Agio</th>
                    <th className='border-bottom py-2'>Signed</th>
                    {contractStatus === 'Declined' &&
                        <th className='border-bottom py-2'>Reason</th>
                    }
                  </tr>
                  </thead>
                  <tbody>
                  {contracts.map((c, index) => (
                      <tr key={index}>
                        <td className='pr-3 py-1'>{c.key}</td>
                        <td className='pr-3 py-1'>{c.investorName}</td>
                        <td className='pr-3 py-1'>{formatCurrency(c.share)}</td>
                        <td className='pr-3 py-1'>{formatCurrency(c.agio)}</td>
                        <td className='pr-3 py-1'>{c.signed || '-'}</td>
                        {contractStatus === 'Declined' &&
                            <td className='pr-3 py-1'>{c.reason || '-'}</td>
                        }
                      </tr>
                  ))}
                  </tbody>
                </table>
                <Paginator first={paginatorFirst} rows={paginatorRows} totalRecords={paginatorTotalRows}
                           rowsPerPageOptions={[5, 10]} onPageChange={onPaginatorChange} ref={paginatorRef}></Paginator>
              </div>
              :
              <p>No contracts.</p>
        }
      </Dialog>
  );
};

export default ContractsModal;
